<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="ANALITICAS">
          <Analiticas></Analiticas>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Analiticas from '@/gmo_votacion/votar/dashboard/components/Analiticas';
export default {
  name: 'Dashboard',
  components: { Analiticas },
  data: () => ({
    page: {
      title: 'Analíticas'
    },
    breadcrumbs: [
      {
        text: 'Votación',
        disabled: true
      },
      {
        text: 'Analíticas',
        disabled: true
      }
    ]
  })
};
</script>

<style scoped></style>
