<template>
  <div>
    <!--    <v-card-text>-->
    <!--      <v-row>-->
    <!--        <v-col cols="12" sm="12" md="12">-->
    <!--          <vue-apex-charts type="bar" height="400" :options="chartOptions" :series="series"></vue-apex-charts>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-card-text>-->

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <vue-apex-charts type="bar" height="800" :options="chartOptions" :series="series"></vue-apex-charts>
        </v-col>
      </v-row>
    </v-card-text>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import fire from '@/fire';
import { decryptData } from '@/components4x/utils/utils4x';

export default {
  name: 'Analiticas',
  components: {
    VueApexCharts
  },
  data: () => ({
    showLoading: false,
    series: [],
    structXaxis: {
      xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        labels: {
          formatter: function(val) {
            return val + 'V';
          }
        }
      }
    },
    structChartOptions: {
      colors: [
        '#1e88e6',
        '#21c1d6',
        '#fc4b6c',
        '#563dea',
        '#9C27b0',
        '#1D4B23',
        '#1B310B',
        '#2E93fA',
        '#66DA26',
        '#546E7A',
        '#E91E63',
        '#FF9800',
        '#33b2df',
        '#546E7A',
        '#d4526e',
        '#13d8aa',
        '#A5978B',
        '#2b908f',
        '#f9a3a4',
        '#90ee7e',
        '#f48024',
        '#69d2e7'
      ],
      chart: {
        type: 'bar',
        height: 800,
        stacked: true
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: 'RANKING DE VOTACIONES'
      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          align: 'left',
          offsetX: 5
        },
        tooltip: {
          enabled: true
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + 'V';
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    },
    chartOptions: {
      chart: {
        type: 'bar',
        height: 800,
        stacked: true
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: 'RANKING DE VOTACIONES'
      },
      xaxis: {
        categories: [],
        labels: {
          align: 'left',
          formatter: function(val) {
            return val + 'V';
          }
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
        labels: {
          align: 'left'
        },
        tooltip: {
          enabled: true
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + 'V';
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
    // series: [
    //   {
    //     name: 'conteo',
    //     data: []
    //   }
    // ],
    // chartOptions: {
    //   chart: {
    //     type: 'bar',
    //     height: 400
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //       barHeight: '100%',
    //       distributed: true
    //     }
    //   },
    //   colors: [
    //     '#33b2df',
    //     '#546E7A',
    //     '#d4526e',
    //     '#13d8aa',
    //     '#A5978B',
    //     '#2b908f',
    //     '#f9a3a4',
    //     '#90ee7e',
    //     '#f48024',
    //     '#69d2e7'
    //   ],
    //   dataLabels: {
    //     enabled: true
    //   },
    //   xaxis: {
    //     categories: []
    //   }
    // },
    // structChartOptions: {
    //   chart: {
    //     type: 'bar',
    //     height: 400
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //       barHeight: '100%',
    //       distributed: true
    //     }
    //   },
    //   colors: [
    //     '#33b2df',
    //     '#546E7A',
    //     '#d4526e',
    //     '#13d8aa',
    //     '#A5978B',
    //     '#2b908f',
    //     '#f9a3a4',
    //     '#90ee7e',
    //     '#f48024',
    //     '#69d2e7'
    //   ],
    //   dataLabels: {
    //     enabled: true
    //   },
    //   yaxis: {
    //     labels: {
    //       show: true,
    //       align: 'right',
    //       minWidth: 0,
    //       maxWidth: 250,
    //       style: {
    //         colors: [],
    //         fontSize: '14px',
    //         fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    //         fontWeight: 400,
    //         cssClass: 'apexcharts-yaxis-label'
    //       },
    //       offsetX: 0,
    //       offsetY: 0,
    //       rotate: 0,
    //       formatter: (value) => {
    //         return value;
    //       }
    //     }
    //   }
    // }
  }),
  methods: {
    async loadCandidates() {
      const itemsRef = fire.database().ref('candidates');
      itemsRef.orderByChild('count').on('value', (snapshot) => {
        let data = snapshot.val();
        this.series[0].data = [];
        this.chartOptions.xaxis.categories = [];
        const categories = [];

        const objectResponse = [];

        Object.keys(data).forEach((key) => {
          objectResponse.push({
            name: data[key].name,
            count: parseInt(data[key].count)
          });
        });

        objectResponse.sort((a, b) => b.count - a.count);

        objectResponse.forEach((el) => {
          this.series[0].data.push(el.count);
          categories.push(el.name);
        });

        this.chartOptions = {
          ...this.structChartOptions,
          xaxis: {
            categories: categories
          }
        };
      });
    },
    async loadGraph() {
      const id = localStorage.getItem('oko3ldkd543');
      if (id === null) return;
      const decrypt = decryptData(id);
      const itemsRef = fire.database().ref('distribucion_candidatos');
      itemsRef
        .child(decrypt)
        .child('body')
        .on('value', (snapshot) => {
          let data = snapshot.val();

          if (data === null) return;

          const groupSeriesMesa = [];
          let serieX = [];

          Object.keys(data).forEach((key) => {
            const groupSeriesCandidatosVoto = [];
            const bulkCandidates = [];
            serieX = [];

            Object.keys(data[key].candidatos).forEach((k) => {
              bulkCandidates.push({
                name: data[key].candidatos[k].name,
                count: data[key].candidatos[k].count,
                id: k
              });
            });

            const orderDescCandidates = this.sortDesc(bulkCandidates);

            orderDescCandidates.forEach((el) => {
              groupSeriesCandidatosVoto.push(el.count);
              serieX.push(el.name);
            });

            groupSeriesMesa.push({
              name: data[key].name,
              data: groupSeriesCandidatosVoto
            });
          });

          const objectResponse = this.sortDesc(groupSeriesMesa);

          this.series = [];

          objectResponse.forEach((el) => {
            this.series.push({ ...el });
          });

          this.chartOptions = {
            ...this.structChartOptions,
            xaxis: {
              categories: [...serieX],
              labels: {
                align: 'left',
                formatter: function(val) {
                  return parseFloat(val).toFixed(1) + 'V';
                }
              },
              tooltip: {
                enabled: true
              }
            }
          };
        });
    },
    sortDesc(data) {
      return data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name === b.name) return 0;
        if (a.name < b.name) return -1;
      });
    }
  },
  async created() {
    await this.loadGraph();
  }
};
</script>

<style scoped></style>
